.main.main--login {
  display: flex;
  align-items: center;
}

.login {
  display: grid;
  background: var(--primary-bg);
  box-shadow: var(--box-shadow-2);
  border-radius: 8px;
  padding: 5rem 2rem;
  max-width: 59rem;
  margin: 0 auto;
}

.form--login {
  width: 100%;
  max-width: var(--dashboard-form-width);
  margin: 0 auto;
}

.login__title {
  color: var(--primary);
}

.login__subtitle {
  --flow-space: 1.5rem;
}
