/*    █▀▄ █▀█ █░░ █░░ █▄█
      █▄▀ █▄█ █▄▄ █▄▄ ░█░   */

.rubric__title,
.parish__city,
h1:not([class]) {
  font-family: dolly, serif;
  font-size: 53px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;

  @media (--media-sm-only) {
    font-size: 40px;
    line-height: 120%;
  }

  @media (--media-verysm-only) {
    font-size: 31px;
  }
}

.teaser__title {
  font-family: dolly, serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;

  @media (--media-sm-only) {
    font-size: 31px;
  }
  @media (--media-verysm-only) {
    font-size: 24px;
  }
}

.agenda__title,
.teaser--agenda .teaser__title,
.teaser--person .teaser__title,
.fourzerofour__undertitle,
.newsletter__title {
  font-family: dolly, serif;
  font-size: 31px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;

  @media (--media-sm-only) {
    font-size: 24px;
  }
}

.teaser__city,
h3:not([class]),
h4:not([class]),
h5:not([class]),
h6:not([class]) {
  font-family: dolly, serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.agenda__event-title {
  font-family: dolly, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 133.333%;
}

body {
  font-family: dolly, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

/*      █▀▀ █░░ ▀█ ▄▀█
        ██▄ █▄▄ █▄ █▀█      */

.article__rubric-title {
  font-family: elza, sans-serif;
  font-size: 47px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;

  @media (--media-sm-only) {
    font-size: 36px;
  }
}

.dashboard__title,
.login__title {
  font-family: elza, sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;

  @media (--media-sm-only) {
    font-size: 27px;
    line-height: 140%;
  }
}

.dashboard__title-link,
h2:not([class]) {
  font-family: elza, sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;

  @media (--media-sm-only) {
    font-size: 27px;
    line-height: 140%;
  }
}

.article--normal blockquote {
  font-family: elza, sans-serif;
  font-size: 27px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;

  @media (--media-sm-only) {
    font-size: 21px;
  }
}

.article--rubric blockquote {
  font-family: elza, sans-serif;
  font-size: 27px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;

  @media (--media-sm-only) {
    font-size: 21px;
    line-height: 140%;
  }
}

.fourzerofour__menu-link.fourzerofour__menu-link--strong {
  font-family: elza, sans-serif;
  font-size: 27px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;

  @media (--media-sm-only) {
    font-size: 21px;
  }
}

.parish__title,
.dashboard__subtitle,
.login__subtitle,
.fourzerofour__menu-link,
.article__lead,
.event__date,
.event__location,
.prose--lead p,
p.prose--lead {
  font-family: elza, sans-serif;
  font-size: 27px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;

  @media (--media-sm-only) {
    font-size: 21px;
  }
}

.skip-link,
.button.button--large {
  font-family: elza, sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: 114.286%;

  @media (--media-sm-only) {
    font-size: 18px;
  }
}

.f3cc,
.scroll-down__current-issue,
.header__menu-link {
  font-family: elza, sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;

  @media (--media-sm-only) {
    font-size: 18px;
  }
}

.dashboard-nav__link,
.teaser__mail,
.teaser__function,
.parish__header-left,
.article__rubric-name,
.agenda__date,
.teaser__date,
.teaser__name {
  font-family: elza, sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;

  @media (--media-sm-only) {
    font-size: 18px;
  }
}

dialog.prose-editor-dialog button,
.object__name,
.object__category,
.object__title,
.object__cell--time,
input[type="file"]::file-selector-button,
.field--button label,
.map__pill,
.article__meta,
.agenda__event-category,
.agenda__time,
.footer__title,
.footer__title-link,
.button,
.teaser__pretitle {
  font-family: elza, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

.calendar__month,
.teaser__rubric-name,
.teaser__category,
.teaser__mission {
  font-family: elza, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.object__cell--date {
  font-family: elza, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.calendar__day--has-events {
  font-family: elza, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1em;
}

.dashboard,
.errorlist,
.object__info,
.imagefield-widget,
.imagefield-widget label,
.ProseMirror p,
.object--new-button,
.dashboard-nav__issue,
.form--login p,
.field:has(> input[type="checkbox"]):not(.field--button) label,
.field:has(> input[type="radio"]):not(.field--button) label,
.infobox,
.parish__information,
.agenda__event-location,
.agenda__event-until,
.footer__menu-link,
.footer__text,
.teaser__excerpt,
input,
select,
textarea {
  font-family: elza, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.event__content,
.article--rubric,
.article--rubric a:not([class]) {
  font-family: elza, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.results__noresponse,
.calendar__table {
  font-family: elza, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.dashboard__description,
.helptext,
.form__link,
.backlinks__link,
label,
legend,
figcaption {
  font-family: elza, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

/*  █░░ █▀▀ ▀█▀ ▀█▀ █▀▀ █▀█ ▄▄ █▀ █▀█ ▄▀█ █▀▀ █ █▄░█ █▀▀
    █▄▄ ██▄ ░█░ ░█░ ██▄ █▀▄ ░░ ▄█ █▀▀ █▀█ █▄▄ █ █░▀█ █▄█  */

.teaser__rubric-name {
  letter-spacing: 0.03em;
}

.teaser__mission,
.teaser__category {
  letter-spacing: 0.48px;
}

.article--normal blockquote {
  letter-spacing: -0.27px;

  @media (--media-sm-only) {
    letter-spacing: -0.21px;
  }
}
