.map {
  max-width: 1008px;
  margin-left: auto;
  margin-right: auto;
  background-color: var(--primary-bg);
  padding: 1.875rem;
  margin-top: 2.5rem;

  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 1.5rem; if in search */

  @media (--media-md) {
    margin-top: 5rem;
    /* margin-top: 2.5rem; if in search */
  }
}
.map__wrapper {
  position: relative;
  max-width: 494px;
  width: 100%;
}

.map__svg {
  width: 100%;
  filter: drop-shadow(4px 0px 16px rgba(0, 0, 0, 0.08));
}

.map__pill {
  border-radius: 1.5rem;
  padding: 0.5rem 1rem;
  background: var(--secondary);
  box-shadow: var(--box-shadow-2);
  position: absolute;
  top: 0px;
  left: 0px;
  pointer-events: none;
  transform: translateY(-50%);
  transition: transform 150ms ease-in-out, opacity 200ms ease-in-out, display
    200ms allow-discrete;
  display: none;
  opacity: 0;

  &.show {
    display: inline-block;
    opacity: 1;
  }
}

@starting-style {
  .map__pill.show {
    opacity: 0;
  }
}

.map__region {
  paint-order: stroke;
  cursor: pointer;
  fill: var(--petrol-light);
  stroke-width: 2px;
  stroke: var(--primary);
  transition: fill 200ms ease-in-out;

  &:hover,
  &.map__region--active {
    fill: var(--petrol-dark);
  }
}

.map__water {
  fill: var(--primary);
  stroke-width: 2px;
  stroke: var(--primary);
}

.parish__image {
  width: 100%;
  margin-top: 2.5rem;
  aspect-ratio: 16 / 9;
  object-fit: cover;

  @media (--media-md) {
    margin-top: 5rem;
  }
}

.parish__header {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2.5rem;
  gap: 2.5rem;

  @media (--media-md) {
    gap: 2.5rem;
    flex-wrap: nowrap;
  }

  @media (--media-lg) {
    gap: 5rem;
  }
}

.parish__header-left {
  width: 100%;

  p {
    --flow-space: 1rem;
  }

  @media (--media-md) {
    width: 60%;
  }
}

.parish__header-right {
  width: 100%;

  @media (--media-md) {
    width: 40%;
  }
}

.parish_city {
  --flow-space: 1.5rem;
  text-wrap: balance;
  hyphens: auto;
}

.parish__city {
  --flow-space: 0px;
  text-wrap: balance;
  hyphens: auto;
}

.parish__text {
  margin-top: 1rem;
}

.parish__information {
  --flow-space: 1.5rem;
  padding: 1.5rem;
  background: var(--secondary);

  a {
    color: var(--fg);

    &:hover,
    &:active {
      color: var(--fg-hover);
    }
  }
}
