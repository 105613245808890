.rubric__image {
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
  max-width: 100%;
  aspect-ratio: 1 / 1;
}

.rubric__image-circle {
  padding: 3rem;
  background-color: var(--rubric-color);
  max-width: 17.5rem;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.article__rubric-header,
.rubric-header {
  margin-top: 2.5rem;
  @media (--media-md) {
    margin-top: 5rem;
  }
}

.rubric-header--only-title {
  display: flex;
  justify-content: center;
}

.rubric__title {
  text-align: center;
  margin-top: 1rem;
  @media (--media-md) {
    margin-top: 2rem;
  }

  .rubric-header--only-title & {
    margin-top: 0px;
    padding: 0px 6.8px;
    background-color: var(--rubric-color);
    display: inline-block;
  }
}
