.hide {
  display: none !important;
}

.sr-only {
  width: 0px;
  height: 0px;
  position: absolute;
  overflow: hidden;
  left: 0px;
  top: 0px;

  &.skip-link:focus-visible {
    height: auto;
    width: auto;
    left: 2rem;
    top: 2rem;
    padding: 0.5rem 1.5rem;
    background-color: var(--primary);
    color: var(--white);
    z-index: var(--z-skiplinks);
  }
}

.print-only {
  display: none;

  @media print {
    display: unset;
  }
}

.flow > * + * {
  /* flow uses em to auto-adapt to the font-size of the following element */
  margin-block-start: var(--flow-space, 1em);
}
