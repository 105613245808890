@font-face {
  font-family: elza;
  src: url("./Elza-Thin.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: elza;
  src: url("./Elza-Extralight.woff2") format("woff2");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: elza;
  src: url("./Elza-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: elza;
  src: url("./Elza-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: elza;
  src: url("./Elza-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: elza;
  src: url("./Elza-Semibold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: elza;
  src: url("./Elza-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: elza;
  src: url("./Elza-Black.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: elza;
  src: url("./Elza-ThinOblique.woff2") format("woff2");
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: elza;
  src: url("./Elza-ExtralightOblique.woff2") format("woff2");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: elza;
  src: url("./Elza-LightOblique.woff2") format("woff2");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: elza;
  src: url("./Elza-Oblique.woff2") format("woff2");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: elza;
  src: url("./Elza-MediumOblique.woff2") format("woff2");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: elza;
  src: url("./Elza-SemiboldOblique.woff2") format("woff2");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: elza;
  src: url("./Elza-BoldOblique.woff2") format("woff2");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: elza;
  src: url("./Elza-BlackOblique.woff2") format("woff2");
  font-weight: 900;
  font-style: italic;
}
