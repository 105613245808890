.agenda {
  width: 100%;
  margin-top: 1.75rem;
  border-collapse: collapse;
  table-layout: auto;

  @media (--media-md) {
    margin-top: 3.5rem;

    tr td:last-child {
      padding-left: 1rem;
    }
  }

  td,
  th {
    vertical-align: top;

    @media (--media-md) {
      padding: 0.5rem;
    }
  }

  th {
    text-align: left;
  }

  tr:last-child:not(.load-more) {
    border-bottom: 1px solid var(--primary);
  }

  @media (--media-sm-only) {
    tr,
    td,
    th {
      display: block;
      width: 100%;
      text-align: left;
    }

    tr {
      padding: 0.5rem;

      &:first-child {
        padding-top: 0px;
      }
    }
  }
}

tr:has(.agenda__day),
tr.load-more,
tr.results__noresponse {
  > td,
  > th {
    padding-top: 0px;
  }

  &:not(:first-child):not(.sr-only + tr) {
    border-top: 1px solid var(--primary);

    @media (--media-md) {
      > td,
      > th {
        padding-top: 1rem;
      }
    }
  }
}

.search + .agenda {
  margin-top: 2.5rem;

  tr:has(.agenda__day):first-child {
    border-top: 1px solid var(--primary);

    @media (--media-sm-only) {
      padding-top: 1rem;
    }

    @media (--media-md) {
      > td,
      > th {
        padding-top: 1rem;
      }
    }
  }

  @media (--media-md) {
    margin-top: 5rem;
  }
}

@media (--media-sm-only) {
  .agenda__day {
    padding-top: 0.5rem;
    padding-bottom: 1.5rem;
  }

  tr.agenda__row-end {
    padding-bottom: 1rem;
  }

  tr.load-more,
  tr.results__noresponse {
    padding-top: 1rem;
  }
}

@media (--media-md) {
  th.agenda__title-cell {
    padding-bottom: 2.5rem;
  }
  .agenda__day {
    padding-right: 1rem;
  }

  .agenda__day,
  .agenda__row-end td {
    padding-bottom: 1rem;
  }
}

.agenda__day {
  width: 25%;
}

.agenda__col-time {
  text-align: right;
  width: 25%;
}
.agenda__col-event {
  width: 50%;
}

.agenda__time {
  text-wrap: balance;
  white-space: nowrap;
  font-variant-numeric: tabular-nums;
}

.agenda__date {
  color: var(--primary);
  text-wrap: balance;
  display: inline-block;
  font-variant-numeric: tabular-nums;
}

/*  █▀▀ █░█ █▀▀ █▄░█ ▀█▀
    ██▄ ▀▄▀ ██▄ █░▀█ ░█░  */

.agenda__event-category {
  color: var(--primary);
  text-wrap: balance;
}

ul.agenda__event-category {
  display: flex;
  flex-wrap: wrap;

  li {
    text-wrap: balance;
    &:not(:last-child):after {
      content: ",\00a0";
    }
  }
}

.agenda__event-title {
  text-wrap: balance;
  hyphens: auto;
}

.agenda__event-location {
  text-wrap: balance;
  hyphens: auto;
}

.agenda__event-with-livestream {
  height: 1.5rem;
  margin-left: 0px;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  pointer-events: none;
}

/* .event{} */

.event__content {
  margin-top: 2.5rem;

  @media (--media-md) {
    margin-top: 5rem;
  }
}

.event__image-wrapper {
  margin-top: 2.5rem;

  @media (--media-md) {
    margin-top: 5rem;
  }
}

.event__date {
  text-align: center;
  text-wrap: balance;
  margin-top: 1.25rem;

  @media (--media-md) {
    margin-top: 2.5rem;
  }
}

.event__location {
  text-align: center;
  text-wrap: balance;
}

.event__with-livestream {
  height: 2.5rem;
  margin-top: 1rem;
  pointer-events: none;
}
