.calendar {
  position: relative;
  width: 100%;
  border-radius: 0.5rem;
  background: var(--gray-lighter);
  padding: 0.75rem 1rem 1.875rem 1rem;

  tr > td + td,
  tr > th + th {
    border-left-width: 12px;
  }

  tr + tr > td,
  tr + tr > th {
    border-top-width: 12px;
  }
}

.calendar--large {
  margin-top: 3.75rem;
  max-width: var(--content-width);
  margin-left: auto;
  margin-right: auto;

  table.calendar__table {
    width: 100%;
  }

  @media (--media-md) {
    margin-top: 7.5rem;
  }
}

table.calendar__table {
  border-collapse: collapse;
  border-style: hidden;
  border-spacing: 0px;
  margin: 0px auto;
}

th.calendar__month,
th.calendar__week,
td.calendar__day {
  padding: 0px;
  height: 28px;
  width: 40px;
  text-align: center;
  vertical-align: middle;
  box-sizing: content-box;
  border: 0px solid transparent;
}

th.calendar__week {
  font-weight: inherit;
}

.calendar__day--has-events.calendar__day--in-month {
  position: relative;

  &::before {
    display: block;
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    width: 36px;
    height: 36px;
    background-color: var(--shade, purple);
  }
}

.calendar__number {
  position: relative;
  z-index: 1;
  text-decoration: none;
  color: var(--fg);
  font-variant-numeric: tabular-nums;
}

.calendar__day:not(.calendar__day--in-month) {
  --fg: rgba(0, 0, 0, 0.2);
}

.calendar__buttons {
  margin-top: 22px;
  display: flex;
  justify-content: center;
}

.calendar__arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  @media (--media-mobile) {
    top: 1.75rem;
  }
}

.calendar__arrow--right {
  right: 2rem;

  @media (--media-desktop) {
    right: -3rem;
  }
}

.calendar__arrow--left {
  left: 2rem;

  @media (--media-desktop) {
    left: -3rem;
  }
}

/* .color: var(--color-fixed-primary-font, #101010); */
/* text-align: center; */
