:root {
  --petrol-lighter: #def6fe;
  --petrol-light: #a3e3fa;
  --petrol-dark: #34cdfe;
  --petrol-darker: #005773;

  --primary: #007cb0;
  --primary-bg: var(--petrol-lighter);
  --secondary: #fcfe7f;
  --black: #101010;
  --white: #fdfdfd;

  --f3cc-foreground: var(--black);
  --f3cc-background: var(--primary-bg);
  --f3cc-accept-background: var(--primary);
  --f3cc-accept-foreground: var(--white);
  --f3cc-button-foreground: var(--white);
  --f3cc-button-background: var(--black);

  --apricot-lighter: #fbede0;
  --apricot-light: #fbcfa2;
  --apricot-dark: #f8a851;
  --apricot-darker: #dd7709;

  --green-lighter: #e4f7cf;
  --green-light: #b0e377;
  --green-dark: #00c814;
  --green-darker: #37a810;

  --gray-lighter: #f0f0f0;
  --gray-light: #d6d6d6;
  --gray-dark: #afafaf;
  --gray-darker: #919191;

  --plum-lighter: #f2e2fd;
  --plum-light: #e3bdf5;
  --plum-dark: #bf6fe6;
  --plum-darker: #a755cd;

  --berry-lighter: #fce8f0;
  --berry-light: #f6b7d1;
  --berry-dark: #ff70ac;
  --berry-darker: #ec609b;

  --yellow-lighter: #fff999;
  --yellow-light: #faf061;
  --yellow-dark: #e6dc0f;
  --yellow-darker: #9c9502;

  --fg: var(--black);
  --bg: var(--white);
  --fg-hover: var(--primary);

  --button-default-fg: var(--white);
  --button-default-bg: var(--primary);
  --button-default-border: var(--primary);
  --button-default-hover-fg: var(--black);
  --button-default-hover-bg: var(--white);
  --button-default-hover-border: var(--black);

  --button-fg: var(--button-default-fg);
  --button-bg: var(--button-default-bg);
  --button-border: var(--button-default-border);
  --button-hover-fg: var(--button-default-hover-fg);
  --button-hover-bg: var(--button-default-hover-bg);
  --button-hover-border: var(--button-default-hover-border);

  --button-dark-fg: var(--white);
  --button-dark-bg: var(--black);
  --button-dark-border: var(--black);
  --button-dark-hover-fg: var(--black);
  --button-dark-hover-bg: var(--white);
  --button-dark-hover-border: var(--black);

  --button-toggle-fg: var(--black);
  --button-toggle-bg: var(--white);
  --button-toggle-border: var(--black);
  --button-toggle-hover-fg: var(--white);
  --button-toggle-hover-bg: var(--black);
  --button-toggle-hover-border: var(--black);
  --button-toggle-active-fg: var(--white);
  --button-toggle-active-bg: var(--black);
  --button-toggle-active-border: var(--black);

  --button-filter-fg: var(--primary);
  --button-filter-bg: var(--white);
  --button-filter-border: var(--primary);
  --button-filter-hover-fg: var(--white);
  --button-filter-hover-bg: var(--primary);
  --button-filter-hover-border: var(--primary);
  --button-filter-active-fg: var(--white);
  --button-filter-active-bg: var(--primary);
  --button-filter-active-border: var(--primary);

  --error-bg: #f8b4b4;
  --error-button: #c53030;
  --error-fg: #f00;

  --box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  --box-shadow-2: 4px 0px 16px 0px rgba(0, 0, 0, 0.08);
  --layout-width: 78rem;
  --text-width: 50rem;
  --content-width: 37rem;
  --layout-padding: 1rem;

  --header-height: 130px;
  --header-height-full: 101px;
  --header-height-sub: 171px;
  --header-height-lead: auto;
  --header-height-mobile: 76px;

  --header-height-login: 58px;
  --aside-width: 18rem;
  --dashboard-layout-width: 65rem;
  --dashboard-form-width: 33rem;

  --z-hidden: -5;
  --z-subnavi: 6;
  --z-navi: 8;
  --z-header: 10;
  --z-overlay: 20;
  --z-skiplinks: 50;
}

.family-color {
  --lighter: tomato;
  --light: red;
  --dark: crimson;
  --darker: darkred;
}

.family-color--petrol {
  --lighter: var(--petrol-lighter);
  --light: var(--petrol-light);
  --dark: var(--petrol-dark);
  --darker: var(--petrol-darker);
}

.family-color--apricot {
  --lighter: var(--apricot-lighter);
  --light: var(--apricot-light);
  --dark: var(--apricot-dark);
  --darker: var(--apricot-darker);
}

.family-color--green {
  --lighter: var(--green-lighter);
  --light: var(--green-light);
  --dark: var(--green-dark);
  --darker: var(--green-darker);
}

.family-color--gray {
  --lighter: var(--gray-lighter);
  --light: var(--gray-light);
  --dark: var(--gray-dark);
  --darker: var(--gray-darker);
}

.family-color--plum {
  --lighter: var(--plum-lighter);
  --light: var(--plum-light);
  --dark: var(--plum-dark);
  --darker: var(--plum-darker);
}

.family-color--berry {
  --lighter: var(--berry-lighter);
  --light: var(--berry-light);
  --dark: var(--berry-dark);
  --darker: var(--berry-darker);
}

.family-color--yellow {
  --lighter: var(--yellow-lighter);
  --light: var(--yellow-light);
  --dark: var(--yellow-dark);
  --darker: var(--yellow-darker);
}

.color--petrol-light {
  --rubric-color: var(--petrol-light);
}

.color--petrol-dark {
  --rubric-color: var(--petrol-dark);
}

.color--apricot-lighter {
  --rubric-color: var(--apricot-lighter);
}

.color--apricot-light {
  --rubric-color: var(--apricot-light);
}

.color--apricot-dark {
  --rubric-color: var(--apricot-dark);
}

.color--green-lighter {
  --rubric-color: var(--green-lighter);
}

.color--green-light {
  --rubric-color: var(--green-light);
}

.color--green-dark {
  --rubric-color: var(--green-dark);
}

.color--gray-light {
  --rubric-color: var(--gray-light);
}

.color--gray-dark {
  --rubric-color: var(--gray-dark);
}

.color--plum-light {
  --rubric-color: var(--plum-light);
}

.color--plum-dark {
  --rubric-color: var(--plum-dark);
}

.color--berry-lighter {
  --rubric-color: var(--berry-lighter);
}

.color--berry-light {
  --rubric-color: var(--berry-light);
}

.color--berry-dark {
  --rubric-color: var(--berry-dark);
}

.color--yellow-lighter {
  --rubric-color: var(--yellow-lighter);
}

.color--yellow-light {
  --rubric-color: var(--yellow-light);
}

.color--yellow-dark {
  --rubric-color: var(--yellow-dark);
}

/* Additional styles for dark colors */
.color--petrol-dark,
.color--apricot-dark,
.color--green-dark,
.color--gray-dark,
.color--plum-dark,
.color--berry-dark,
.color--yellow-dark {
  --rubric-sup-color: var(--black);

  --button-fg: var(--button-dark-fg);
  --button-bg: var(--button-dark-bg);
  --button-border: var(--button-dark-border);
  --button-hover-fg: var(--button-dark-hover-fg);
  --button-hover-bg: var(--button-dark-hover-bg);
  --button-hover-border: var(--button-dark-hover-border);
}

/* Adjacent lighter color classes */
.color--apricot-lighter + .color--apricot-lighter:nth-child(even),
.color--green-lighter + .color--green-lighter:nth-child(even),
.color--berry-lighter + .color--berry-lighter:nth-child(even),
.color--yellow-lighter + .color--yellow-lighter:nth-child(even) {
  /* Copy of .button--toggle */
  --button-fg: var(--button-toggle-fg);
  --button-bg: var(--button-toggle-bg);
  --button-border: var(--button-toggle-border);
  --button-hover-fg: var(--button-toggle-hover-fg);
  --button-hover-bg: var(--button-toggle-hover-bg);
  --button-hover-border: var(--button-toggle-hover-border);
}
