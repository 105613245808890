.bricks {
  display: grid;
  gap: 1rem;
  margin-block-start: 1rem;
  grid-template-columns: 1fr;

  --fg: var(--black);

  --font-size-lg: 40px;
  --font-size-md: 31px;
  --font-size-sm: 24px;

  @media (--media-sm-only) {
    --font-size-lg: 31px;
    --font-size-md: 24px;
    --font-size-sm: 24px;
  }

  @media (--media-verysm-only) {
    --font-size-lg: 24px;
    --font-size-md: 24px;
    --font-size-sm: 24px;
  }

  @media (--media-md) {
    gap: 2rem;
    margin-block-start: 2.5rem;
  }

  /* & + .bricks {
    margin-block-start: 1rem;

    @media (--media-md) {
      margin-block-start: 2rem;
    }
  } */

  .prose + & {
    margin-block-start: 1.75rem;

    @media (--media-md) {
      margin-block-start: 3.5rem;
    }
  }

  .prose--lead + & {
    margin-block-start: 2.5rem;
    @media (--media-md) {
      margin-block-start: 5rem;
    }
  }
}

.bricks > * {
  /* Stop filling the grid boxes */
  align-self: flex-start;
}

/* grid-area: row-start / column-start / row-end / column-end */

.bricks--1 {
  @media (--media-desktop) {
    .teaser--article {
      .teaser__rubric-name-wrapper {
        flex: 100%;
      }

      .teaser__inner {
        display: flex;
        flex-wrap: wrap;
        gap: 0px 2rem;
      }

      .teaser__image-wrapper {
        flex: 0 1 calc(50% - 1rem);
      }

      .teaser__image {
        height: 100%;
      }

      .teaser__inner-content {
        flex: 0 1 calc(50% - 1rem);
      }
    }
  }
}

.bricks--2 {
  @media (--media-md) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .teaser--article .teaser__title {
    font-size: var(--font-size, var(--font-size-md));
  }
}

.bricks--3 {
  @media (--media-md) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (--media-lg) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .teaser--article {
    .teaser__image-wrapper,
    .teaser__image {
      display: none;
    }

    .teaser__title {
      font-size: var(--font-size, var(--font-size-sm));
    }
  }
}

.bricks--3a {
  @media (--media-md) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (--media-lg) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  > :nth-child(1) {
    grid-area: auto;

    @media (--media-md) {
      grid-area: 1 / 1 / 3 / 2;
    }

    @media (--media-lg) {
      grid-area: 1 / 1 / 3 / 3;
    }

    &.teaser--article .teaser__title {
      font-size: var(--font-size, var(--font-size-lg));
    }
  }

  > :nth-child(2),
  > :nth-child(3) {
    grid-column: auto;

    @media (--media-md) {
      grid-column: 2 / 3;
    }

    @media (--media-lg) {
      grid-column: 3 / 4;
    }

    &.teaser--article {
      .teaser__image-wrapper,
      .teaser__image,
      .teaser__excerpt {
        display: none;
      }

      .teaser__title {
        font-size: var(--font-size, var(--font-size-sm));
        hyphens: auto;
      }
    }
  }
}

.bricks--3b {
  @media (--media-md) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  > :nth-child(1),
  > :nth-child(2) {
    grid-column: auto;

    @media (--media-md) {
      grid-column: 1 / 2;
    }

    &.teaser--article {
      .teaser__image-wrapper,
      .teaser__image {
        display: none;
      }

      .teaser__title {
        font-size: var(--font-size, var(--font-size-md));
        hyphens: auto;
      }
    }
  }

  > :nth-child(3) {
    grid-area: auto;

    @media (--media-md) {
      grid-area: 1 / 2 / 3 / 3;
    }

    &.teaser--article .teaser__title {
      font-size: var(--font-size, var(--font-size-md));
    }
  }
}

.current-issue {
  padding-right: 12px;
  padding-bottom: 12px;

  img {
    overflow: hidden;
    border: 1px solid var(--gray-light);
    border-radius: 0.5rem;
    box-shadow: 12px 12px 0px 0px var(--primary);
  }
}
