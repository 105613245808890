@font-face {
  font-family: dolly;
  src: url("./dolly-pro-reg.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: dolly;
  src: url("./dolly-pro-bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: dolly;
  src: url("./dolly-pro-reg-it.woff2") format("woff2");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: dolly-smcp;
  src: url("./dolly-pro-reg-smcp.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-feature-settings: "smcp";
}
