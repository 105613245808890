/*  █▄░█ █▀▀ █░█░█ █▀ █░░ █▀▀ ▀█▀ ▀█▀ █▀▀ █▀█
    █░▀█ ██▄ ▀▄▀▄▀ ▄█ █▄▄ ██▄ ░█░ ░█░ ██▄ █▀▄   */

.newsletter {
  border-radius: 0.5rem;
  background: var(--secondary);
  padding: 1rem;
  margin-top: 2.5rem;

  @media (--media-md) {
    margin-top: 5rem;
    padding: 2rem;
  }
}

.newsletter--grid {
  margin-top: 0px;

  @media (--media-md) {
    margin-top: 0px;
  }
}

.newsletter--bricks {
  margin-top: 1rem;

  @media (--media-md) {
    margin-top: 2.5rem;
  }
}

.newsletter__form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;

  @media (--media-md) {
    flex-wrap: nowrap;
    gap: 2rem;
  }
}

.newsletter__title {
  text-wrap: balance;

  @media (--media-sm-only) {
    text-align: center;
  }
}

.newsletter__buttons {
  display: inline-flex;
  justify-content: center;

  @media (--media-sm-only) {
    width: 100%;
  }
}

/*  █ █▄░█ █▀▀ █▀█ █▄▄ █▀█ ▀▄▀
    █ █░▀█ █▀░ █▄█ █▄█ █▄█ █░█  */

.infobox {
  --flow-space: 2.5rem;
  --fg: var(--black);

  max-width: var(--content-width);
  margin-left: auto;
  margin-right: auto;
  color: var(--fg);
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (--media-md) {
    --flow-space: 5rem;
    flex-direction: row;
    gap: 2rem;
  }
}

.infobox--full {
  max-width: var(--text-width);
}

.infobox__image-wrapper {
  width: 100%;

  @media (--media-md) {
    flex: 0 0 300px;
  }
}

.infobox__image {
  display: block;
}

.infobox__text {
  background-color: var(--secondary);
  padding: 1.5rem;
  --flow-space: 1em;
  flex: 1 1 auto;

  @media (--media-md) {
    padding: 1.5rem;
  }
}

/*  █▀█ █░█ █▄▄ █░░ █ █▀ █░█ █ █▄░█ █▀▀ ▄▄ █▀█ █░░ ▄▀█ █▄░█
    █▀▀ █▄█ █▄█ █▄▄ █ ▄█ █▀█ █ █░▀█ █▄█ ░░ █▀▀ █▄▄ █▀█ █░▀█  */

.publishing-plan-wrapper {
  max-width: 100%;
  overflow: auto;
}

table.publishing-plan {
  width: 100%;
  border-collapse: collapse;
  border: none;
  margin-top: 1.25rem;

  @media (--media-md) {
    margin-top: 2.5rem;
  }

  th,
  td {
    padding: 0.25rem 1rem;
    text-wrap: balance;

    &:nth-child(1) {
      text-align: center;
      white-space: nowrap;
    }
    &:nth-child(2) {
      text-align: right;
      white-space: nowrap;
    }
    &:nth-child(3) {
      text-align: left;
      width: auto;
      hyphens: auto;
      min-width: 250px;
    }
    &:nth-child(4) {
      text-align: right;
      white-space: nowrap;
    }
  }

  tbody tr:nth-child(even) {
    background-color: var(--primary-bg);
  }
  tbody tr:nth-child(odd) {
    background-color: white;
  }

  thead tr {
    background-color: var(--primary);
    color: var(--white);
  }
}

/*  █▀▄ █▀█ █░█░█ █▄░█ █░░ █▀█ ▄▀█ █▀▄ █▀
    █▄▀ █▄█ ▀▄▀▄▀ █░▀█ █▄▄ █▄█ █▀█ █▄▀ ▄█   */

.download {
  margin-top: 2rem;
}

/*  █▀▀ ▄▀█ █▀█
    █▀░ █▀█ ▀▀█   */

details.faq__details {
  background-color: var(--primary-bg);
  margin-top: 1rem;
  height: var(--collapsed, 0px);
  transition: height 250ms ease-in-out;

  &[open] {
    height: var(--expanded, 0px);
  }
}

summary.faq__summary {
  background-color: var(--primary);
  padding: 1rem;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    transition: transform 250ms ease-in-out;
    [open] & {
      transform: rotate(-180deg);
    }
  }
}

.faq__content {
  padding: 1rem;
}
