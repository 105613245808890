.prose {
  max-width: 100%;

  h1,
  h3,
  h4,
  h5,
  h6 {
    text-wrap: balance;
    max-width: var(--text-width);

    main:not(.main--left, .main--dashboard) & {
      margin-left: auto;
      margin-right: auto;
    }
  }

  h1 {
    hyphens: auto;
    main:not(.main--left) & {
      text-align: center;
    }
  }

  h1:first-child {
    margin-top: 2.5rem;

    @media (--media-md) {
      margin-top: 5rem;
    }
  }

  h2:not([class]) {
    margin-top: 3.75rem;
    padding-bottom: 0.75rem;
    border-bottom: 1px solid var(--primary);

    main:not(.main--left, .main--agenda, .main--dashboard) & {
      text-align: center;
    }

    @media (--media-md) {
      margin-top: 7.5rem;
      padding-bottom: 1.3125rem;
    }

    main.main--dashboard & {
      margin-top: 3.4375rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (--media-md) {
        margin-top: 6.875rem;
      }
    }
  }

  p,
  > a {
    max-width: var(--text-width);
    display: block;

    main:not(.main--left) & {
      margin-left: auto;
      margin-right: auto;
    }
  }

  a:not([class]) {
    color: var(--fg);

    &:hover,
    &:active {
      color: var(--fg-hover);
    }
  }

  ol,
  ul {
    margin-inline: auto;
    max-width: var(--text-width);
    padding-left: 2ch;
  }

  ol ol {
    list-style: lower-latin;
  }

  ol ol ol {
    list-style: lower-roman;
  }

  hr {
    margin-top: 1.5rem;

    @media (--media-md) {
      margin-top: 3rem;
    }
  }

  .figure {
    --flow-space: 1.5rem;

    @media (--media-md) {
      --flow-space: 3rem;
    }
  }

  blockquote {
    text-wrap: balance;
    max-width: var(--content-width);
    margin-left: auto;
    margin-right: auto;
  }

  .download {
    text-align: left;

    main:not(.main--left, .main--dashboard) & {
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }
  }
}

.prose--lead,
.prose--lead > * {
  text-align: center;
  text-wrap: balance;
  main.main--left & {
    text-align: left;
  }
}

.figure {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  & :where(img, audio) {
    margin: 0 auto;
    flex: 1 1 100%;
  }
}

.newsletter + .prose h2:not([class]) {
  margin-top: 2.5rem;
  @media (--media-md) {
    margin-top: 5rem;
  }
}

.prose + .prose {
  margin-block-start: 1em;
}
