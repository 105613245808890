.article--rubric {
  --fg: var(--black);
  --fg-hover: var(--primary);

  .article__header {
    --fg: var(--primary);
    --fg-hover: var(--black);
  }
}

.article__header {
  color: var(--fg);
  margin-top: 2.5rem;

  @media (--media-md) {
    margin-top: 5rem;
  }

  .article__rubric-header + & {
    margin-top: 1rem;

    @media (--media-md) {
      margin-top: 2rem;
    }
  }
}

.article__lead {
  margin-top: 1.25rem;
  text-align: center;
  text-wrap: balance;

  @media (--media-md) {
    margin-top: 2.5rem;
  }
}

.article__lead-image-wrapper {
  margin-top: 2.5rem;

  @media (--media-md) {
    margin-top: 5rem;
  }
}

.article__image-wrapper {
  --flow-space: 2.5rem;

  @media (--media-md) {
    --flow-space: 5rem;
  }
}

.article__content {
  color: var(--fg);

  .figure {
    max-width: var(--content-width);
    margin-left: auto;
    margin-right: auto;

    --flow-space: 1.5rem;

    @media (--media-md) {
      --flow-space: 3rem;
    }
  }

  a:not([class]) {
    color: var(--fg);
    transition: color 200ms ease-in-out;

    &:hover,
    &:active {
      color: var(--fg-hover);
    }
  }
}

.article__image,
.article__lead-image {
  width: 100%;
}

p.article__rubric-name {
  text-align: center;
}

.article__rubric-title {
  margin-top: 10px;
}

p.article__meta {
  text-align: center;
  text-wrap: balance;
  margin-top: 1rem;

  @media (--media-md) {
    margin-top: 2rem;
  }
}

.article__content {
  .article--rubric & {
    margin-top: 2.5rem;

    @media (--media-md) {
      margin-top: 2.75rem;
    }
  }

  .article--normal & {
    margin-top: 3rem;

    @media (--media-md) {
      margin-top: 6.25rem;
    }
  }
}

.article--normal {
  blockquote {
    margin-top: 1.5rem;
    margin-bottom: 2.5rem;
    @media (--media-md) {
      margin-top: 3rem;
      margin-bottom: 5rem;
    }
  }
}

.article--rubric {
  blockquote {
    margin-top: 1.5rem;
    margin-bottom: 2.5rem;
    @media (--media-md) {
      margin-top: 2.75rem;
      margin-bottom: 4.75rem;
    }
  }

  .article__lead-image-wrapper {
    background-color: var(--rubric-color, transparent);
    width: 100%;
  }

  .article__lead-image {
    max-height: calc(100vh - var(--header-height-full));
    aspect-ratio: 12 / 5;
    object-fit: cover;
    object-position: center;
  }

  .article__meta {
    margin-bottom: 2.5rem;

    @media (--media-md) {
      margin-bottom: 5rem;
    }
  }
}

/* Dont add "-webkit-initial-letter: 4" because Safari dont support initial-letter + font-familie  */
@supports (selector(::first-letter)) {
  .article--normal
    .article__content
    .prose:first-child
    > p:first-of-type::first-letter {
    margin-right: 1rem;
    text-align: justify;

    font-family: elza, sans-serif;
    font-size: 55px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    float: left;

    @media (--media-md) {
      font-size: 105px;
    }

    @supports (initial-letter: 4) {
      -webkit-initial-letter: 2;
      /* biome-ignore lint/nursery/noDuplicateProperties: autoprefixer is lacking */
      initial-letter: 2;

      @media (--media-md) {
        -webkit-initial-letter: 4;
        initial-letter: 4;
      }
    }
  }
}
