.header {
  width: 100%;
  height: var(--header-height);
  pointer-events: none;

  @media (--media-mobile) {
    --header-height: var(--header-height-mobile);
  }
}

.header--full {
  --header-height: var(--header-height-full);

  @media (--media-mobile) {
    --header-height: var(--header-height-mobile);
  }
}

.header--sub {
  --header-height: var(--header-height-sub);

  @media (--media-mobile) {
    --header-height: var(--header-height-mobile);
  }
}

.header--login {
  --header-height: var(--header-height-login);
  margin-top: 2.5rem;

  @media (--media-mobile) {
    --header-height: var(--header-height-mobile);
    margin-top: 1rem;
  }
}

.header--lead {
  --header-height: var(--header-height-lead);
  aspect-ratio: 16 / 9;
  max-height: 100vh;
  max-width: 100%;

  @media (--media-sm-only) {
    aspect-ratio: 5 / 6;
  }
}

.header__lead {
  position: relative;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  pointer-events: all;
}

.header__fixed {
  position: fixed;
  top: 2.5rem;
  width: 100%;
  z-index: var(--z-header);

  @media (--media-mobile) {
    top: 1rem;
  }
}

.header__static {
  position: relative;
  width: 100%;
  pointer-events: all;
}

.header__bar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-radius: 2rem;
  background-color: var(--primary-bg);
  box-shadow: 0px 0px 64px -20px rgba(0, 0, 0, 0.15);
  padding: 1rem 2.5rem;
  pointer-events: all;
  position: relative;
  z-index: var(--z-header);
  transition: background-color 250ms ease-in-out;

  .body--scrolled & {
    background-color: var(--white);
  }

  &.header__items {
    z-index: var(--z-navi);

    @media (--media-mobile) {
      border-radius: 1rem;
    }
  }

  .header__items--sub & {
    z-index: var(--z-subnavi);

    @media (--media-mobile) {
      border-radius: 1rem;
    }
  }
}

.header__items {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.header__logo-small {
  object-fit: cover;
  object-position: left;
  display: block;
  height: 28px;
  width: auto;
}

.header__logo-login {
  height: 58px;
  margin-left: 0;
}

@media (--media-desktop) {
  .header__logo {
    display: inline-block;
    object-fit: contain;
    height: 88px;
    opacity: 1;
    transition: opacity 250ms ease-in-out;

    .body--scrolled & {
      opacity: 0;
      position: absolute;
      left: 0px;
      top: 0px;
    }
  }

  .header__home {
    pointer-events: all;
    position: relative;
    .body--scrolled & {
      pointer-events: none;
    }
  }

  nav.header__menu {
    --logo-small-width: 127px;
    display: inline-flex;
    gap: 1.5rem;
  }

  nav.header__menu--small {
    padding: 1rem 2.5rem 1rem 1rem;
    width: var(--initial-width, auto);

    transition: background-color 250ms ease-in-out, width 200ms ease-in-out;

    .body--scrolled & {
      background-color: var(--white);
      width: 100%;
      padding: 1rem 2.5rem;
    }
  }

  .header__home-small {
    opacity: 0;
    display: block;
    position: relative;
    margin-right: auto;
    width: 0px;

    transition: width 250ms ease-in-out, opacity 250ms ease-in-out;

    .body--scrolled & {
      opacity: 1;
      width: var(--logo-small-width);
    }
  }

  .header__home-full {
    display: inline-block;
  }

  .header__home-login {
    pointer-events: all;
    position: relative;
  }

  .header__logo-full {
    display: block;
    height: 28px;
    width: var(--logo-small-width);
  }
}

.header__menu-link {
  position: relative;
  padding: 0 0.5rem;
  color: var(--primary);
  transition: color 200ms ease-in-out;

  &:hover,
  &:active,
  .header--parish &[data-type="parishes"] {
    color: var(--black);
  }

  &.active,
  .header--parish &[data-type="parishes"] {
    color: var(--black);
    &::before {
      position: absolute;
      top: 50%;
      left: -0.5rem;
      transform: translateY(-50%);
      content: "";
      height: 0.5rem;
      width: 0.5rem;
      display: block;
      background: url(../icons/active.svg) center center no-repeat;

      @media (--media-mobile) {
        left: -1rem;
      }
    }
  }
}

.header__items--sub {
  display: flex;
  justify-content: center;

  .header__bar {
    display: inline-flex;
    margin-top: 0.5rem;
    background-color: var(--primary);
  }

  .header__menu-link {
    color: var(--white);

    &:hover,
    &:active {
      color: var(--black);
    }

    &.active {
      color: var(--white);

      &::before {
        background: url(../icons/active-sub.svg) center center no-repeat;
      }
    }
  }
}

@media (--media-mobile) {
  .header__home {
    display: none;
  }

  .header__items {
    flex-wrap: wrap;
  }

  nav.header__menu {
    width: 100%;
  }

  .header__bar:not(.header__mobile) {
    opacity: 0;
    display: none;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    /* gap: 1rem; */
    margin-top: 0.5rem;

    transition: opacity 200ms ease-in-out, display 200ms allow-discrete;

    .header__home-small,
    .header__home-full {
      display: none;
    }
  }

  .header__menu-link {
    display: block;
    width: 100%;
    padding: 0;

    + .header__menu-link {
      margin-top: 0.5rem;
    }
  }
}

.header__mobile {
  display: none;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;

  @media (--media-mobile) {
    display: flex;
  }
}

.backlinks {
  width: 100%;
  margin-top: 1.5rem;
  pointer-events: none;
  /* top: calc(var(--header-height-mobile) + 0.75rem); */
  position: static;

  @media (--media-desktop) {
    margin-top: 0px;
    position: absolute;
    top: calc(var(--header-height-full) + 1.5rem);
  }
}

.backlinks--with-sub {
  position: static;
  /* top: calc(var(--header-height-mobile) + 0.75rem); */
  margin-top: 1.5rem;

  @media (--media-desktop) {
    position: static;
    /* top: calc(var(--header-height-sub) + 1.5rem); */
  }

  @media (--media-lg) {
    position: absolute;
    margin-top: 0px;
    top: calc(var(--header-height-full) + 1.5rem);
  }
}

ul.backlinks__list {
  display: flex;
  pointer-events: all;
  gap: 0.5rem;
}

li.backlinks__list-item {
  display: inline-flex;
}

a.backlinks__link {
  display: inline-flex;
  align-items: center;
  color: var(--primary);
  transition: color 200ms ease-in-out;

  &:hover,
  &:active {
    color: var(--black);
  }
}

.scroll-down {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  position: absolute;
  bottom: 1.5rem;
  left: 50%;
  transform: translateX(-50%);

  color: var(--white);

  transition: color 200ms ease-in-out;

  &:hover,
  &:active {
    color: var(--primary);
  }
}

.scroll-down__current-issue {
  text-align: center;
}
