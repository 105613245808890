#menu-toggle {
  position: absolute;
  left: 0;
  top: 0;
  visibility: hidden;
  z-index: var(--z-hidden);
}

#menu-toggle:checked + header.header .header__bar:not(.header__mobile) {
  opacity: 1;
  display: flex;
}

@starting-style {
  #menu-toggle:checked + header.header .header__bar:not(.header__mobile) {
    opacity: 0;
  }
}

.menu-toggle {
  width: 1.75rem;
  height: 1.75rem;
  cursor: pointer;
}

.fourzerofour__undertitle {
  margin-top: 1.25rem;
  @media (--media-md) {
    margin-top: 2.5rem;
  }
}

.fourzerofour__menu {
  display: inline-flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1.25rem;
  @media (--media-md) {
    margin-top: 2.5rem;
  }
}

.fourzerofour__menu-link {
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
  color: var(--primary);
  transition: color 200ms ease-in-out;

  &:hover,
  &:active {
    color: var(--black);
  }
}
