.edit-tools {
  position: fixed;
  z-index: 500;
  left: 0.5rem;
  bottom: 0.5rem;
  background: var(--secondary);
  display: flex;
  align-items: center;
  padding: 0 0 0 8px;
  font-size: 0.9rem;
  font-weight: bold;
  border-radius: 0.6rem;

  .button {
    font-size: 1em;
    margin: 0.25rem;
    padding: 0.5rem 0.6rem;
    display: inline-block;
    min-width: auto;
    border-radius: 0.6rem;
  }
}
