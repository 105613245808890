.form-search {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: flex-end;
}

.form-search--reverse {
  align-items: flex-start;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
}

.form-search--centered {
  display: flex;
  justify-content: center;
}

.form-search--columns {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1.25rem 0.75rem;

  @media (--media-md) {
    justify-content: center;
    gap: 2.5rem 1.5rem;
  }
}

.form-search__filters,
.form-search__categories,
.form-search__last {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 0.5rem 0.5rem;
  width: 100%;

  > .field {
    flex: 0 1 auto;
  }

  @media (--media-md) {
    justify-content: center;
    gap: 1rem 2rem;
  }
}

.form-search__last {
  --button-filter-fg: var(--black);
  --button-filter-bg: var(--white);
  --button-filter-border: var(--black);

  --button-filter-hover-fg: var(--white);
  --button-filter-hover-bg: var(--black);
  --button-filter-hover-border: var(--black);

  --button-filter-active-fg: var(--white);
  --button-filter-active-bg: var(--black);
  --button-filter-active-border: var(--black);
}

.search {
  margin-top: 2.5rem;

  @media (--media-md) {
    margin-top: 5rem;
  }
}

.search--after-title {
  margin-top: 1.75rem;

  @media (--media-md) {
    margin-top: 3.5rem;
  }
}

label.is-required::after,
legend.is-required::after {
  content: "*";
}

.helptext {
  margin-top: 0.25rem;
}

input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="url"],
input[type="date"],
input[type="time"],
select,
textarea {
  display: inline-block;
  border-radius: 0.25rem;
  border: 1px solid var(--gray-light);
  background: var(--white);
  min-width: 0;
  padding: 3px 7px;
  color: var(--fg);
  width: 100%;

  label ~ &,
  legend ~ & {
    margin-top: 0.5rem;
  }

  search & {
    border: 1px solid var(--primary);
    padding: 7px 15px;

    &:user-valid {
      background: var(--secondary);
    }
  }

  &:focus {
    /* TODO check this. Without it, clicking on a label of a select field doesn't show that the select is focussed */
    outline: 1px solid black;
  }

  &[disabled] {
    opacity: 0.4;
    filter: grayscale(1);
    cursor: not-allowed !important /* Override select styles below... */;
  }
}

.ProseMirror,
textarea {
  resize: vertical;
  min-height: 4rem;
  overflow: auto;
}

.prose-editor.disabled {
  opacity: 0.4;
  filter: grayscale(1);
  cursor: not-allowed !important /* Override select styles below... */;
}

.ProseMirror > * + * {
  /* Like .flow, but smaller */
  margin-block-start: 0.5em;
}

input[type="hidden"] {
  --flow-space: 0px;

  &:first-child + * {
    --flow-space: 0px;
  }
}

.field {
  max-width: 100%;
  width: 100%;

  @media (--media-md) {
    gap: 1.5rem;
    width: 284px;
    flex: 1;
  }
}

.field:has(> select):not(.field--selectmultiple) {
  position: relative;

  &::after {
    content: "";
    display: block;
    background: transparent url("../icons/chevron-down.svg") center center
      no-repeat;
    background-size: contain;
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    right: 0.5rem;
    top: 2.5rem;
    pointer-events: none;
  }

  select {
    appearance: none;
    cursor: pointer;
    padding-right: 3rem;
  }
}

.field:has(> input[name="query"], > input[name="search"]) {
  position: relative;

  &::after {
    content: "";
    display: block;
    background: transparent url("../icons/search.svg") center center no-repeat;
    background-size: contain;
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    left: 1rem;
    bottom: 0.5rem; /* XXX this isn't good when .help-text exists */
    pointer-events: none;
  }

  input {
    padding-left: 3rem;
  }
}

.results__noresponse {
  margin-block-start: 1.25rem;

  @media (--media-md) {
    margin-block-start: 2.5rem;
  }
}

.field.has-error {
  > label {
    color: var(--error-fg);
    font-style: italic;
  }
  legend {
    color: var(--error-fg);
    font-style: italic;
  }

  input,
  select {
    border-color: var(--error-fg) !important;
  }
}

ul.errorlist {
  color: var(--error-fg);
}

.error-notice {
  color: var(--error-fg);

  + .buttons {
    margin-top: 1rem;
  }
}

.field--button {
  width: auto;
  display: inline-block;
  flex: 0 1 auto;

  input {
    position: absolute;
    visibility: hidden;
    top: 0;
    left: 0;
  }

  label {
    --flow-space: 1.5rem;
    cursor: pointer;
    display: inline-block;
    user-select: none;
    text-decoration: none;
    width: auto;
    text-align: center;
    padding: 7px 23px;
    border-radius: 1.5rem;
    white-space: nowrap;
    transition: color 200ms ease-in-out, background-color 200ms ease-in-out,
      border-color 200ms ease-in-out;

    color: var(--button-filter-fg);
    background: var(--button-filter-bg);
    border: 1px solid var(--button-filter-border);

    &:hover,
    &:active {
      color: var(--button-filter-hover-fg);
      background: var(--button-filter-hover-bg);
      border-color: var(--button-filter-hover-border);
    }
  }

  input:checked + label {
    color: var(--button-filter-active-fg);
    background: var(--button-filter-active-bg);
    border-color: var(--button-filter-active-border);
  }

  input[type="radio"]:checked + label {
    cursor: not-allowed;
  }
}

.field:has(> input[type="checkbox"]):not(.field--button) {
  display: grid;
  gap: 0.25rem 0.5rem;
  grid-template-areas: "checkbox label";
  grid-template-columns: min-content 1fr;

  input {
    grid-area: checkbox;
  }

  label {
    grid-area: label;
  }

  &:has(.helptext) {
    grid-template-areas: "checkbox label" "nothing help";
  }

  .helptext {
    grid-area: help;
  }

  &.field--checkboxinput-vertical {
    grid-template-areas: "label" "checkbox";
    grid-template-columns: 1fr;

    &:has(.helptext) {
      grid-template-areas: "label" "checkbox" "help";
    }
  }

  .form-search & {
    margin-bottom: 8px;
  }

  /*
  input[type="checkbox"] {
    position: absolute;
    visibility: hidden;
    top: 0;
    left: 0;
  }

  label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
  }

  label:before {
    content: "";
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    flex: 0 0 1.5rem;
    margin-right: 10px;
    background-image: url("../icons/checkbox.svg");
    background-size: contain;
    background-repeat: no-repeat;
    transition: background-image 200ms ease-in-out;
  }

  input:checked + label:before,
  label:has(+ input:checked)::before {
    background-image: url("../icons/checkbox-checked.svg");
  }

  &.field--checkboxinput-vertical {
    flex: none;
    label {
      flex-direction: column-reverse;
      gap: 0px;

      &:before {
        margin: 0px;
      }
    }
  }
  */
}

.field:has(> input[type="radio"]):not(.field--button) {
  display: grid;
  gap: 0.25rem 0.5rem;
  grid-template-areas: "checkbox label";
  grid-template-columns: min-content 1fr;

  input {
    grid-area: checkbox;
  }

  label {
    grid-area: label;
  }

  &:has(.helptext) {
    grid-template-areas: "checkbox label" "nothing help";
  }

  .helptext {
    grid-area: help;
  }

  &.field--checkboxinput-vertical {
    grid-template-areas: "label" "checkbox";
    grid-template-columns: 1fr;

    &:has(.helptext) {
      grid-template-areas: "label" "checkbox" "help";
    }
  }

  /*
  width: auto;

  input {
    position: absolute;
    visibility: hidden;
    top: 0;
    left: 0;
  }

  label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
  }

  label:before {
    content: "";
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    flex: 0 0 1.5rem;
    margin-right: 10px;
    background-image: url("../icons/radio.svg");
    background-size: contain;
    background-repeat: no-repeat;
    transition: background-image 200ms ease-in-out;
  }

  input:checked + label:before,
  label:has(+ input:checked)::before {
    background-image: url("../icons/radio-checked.svg");
  }
  */
}

dialog.prose-editor-dialog,
main.main--login,
main.main--dashboard {
  .ProseMirror,
  input[type="text"],
  input[type="email"],
  input[type="number"],
  input[type="password"],
  input[type="url"],
  input[type="date"],
  input[type="time"],
  select,
  textarea,
  fieldset:not(.field--splitdatetime) {
    margin-left: 0px;
    margin-right: 0px;
    display: inline-block;
    border-radius: 0.25rem;
    border: 1px solid var(--primary);
    background: transparent;
    min-width: 0;
    padding: 7px 15px;
    color: var(--fg);
    width: 100%;

    label + &,
    legend + &,
    input + & {
      margin-top: 0.5rem;
    }
  }

  input[type="file"] {
    width: 100%;

    label + & {
      margin-top: 0.5rem;
    }
  }

  .field--clearablefileinputwithpreviewandppoi {
    position: relative;

    .imagefield-preview-image {
      width: 100%;
    }
  }

  @media (--media-md) {
    .field {
      width: 100%;
    }
  }
}

.imagefield-widget {
  a {
    color: var(--fg);

    &:hover,
    &:active {
      color: var(--fg-hover);
    }
  }
}

.field--splitdatetime {
  margin-left: 0px;
  margin-right: 0px;
  padding: 0;
  border: 0;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 0px 0.5rem;

  /* Legends, labels, help texts etc. */
  > * {
    grid-column-end: -1;
    min-inline-size: max-content;
  }

  > input {
    grid-column-end: span 1;
  }
}

.form__link-wrapper {
  display: block;
}

.form__link {
  display: inline-block;
  color: var(--primary);
  text-decoration: underline;
}

rrule-widget {
  display: block;
  unicode-bidi: isolate;
}

.prose-editor {
  --prose-editor-background: transparent;
}

/* FIXME replace this */
.prose-editor blockquote {
  border-left: 1px solid #888;
  padding-left: 8px;
}

.field--sortableupdates {
  .sortable {
    display: flex;
    flex-direction: column;
  }
  .sortable-list-item {
    border: 1px solid var(--primary);
    border-radius: 0.25rem;
    padding: 7px 15px;
    margin: 0.1rem;
    span {
      margin-right: auto;
    }
    display: flex;
    align-items: center;
  }
  .sortable-list-item__controls {
    display: flex;
    align-items: center;
    label {
      cursor: pointer;
      input[type="checkbox"] {
        pointer-events: none;
      }
    }
  }
}

.form--preview {
  .field {
    max-width: 40rem;
  }
  .imagefield-preview {
    max-width: 24rem;
  }
  .field.field--clearablefileinputwithpreviewandppoi {
    > label {
      & + * {
        margin-top: 0.5rem;
      }
    }
  }
  .hidden-by-selection {
    display: none;
  }
}

/* Only for Safari */
@supports (-webkit-hyphens: none) {
  input[type="date"],
  input[type="time"] {
    &::-webkit-datetime-edit {
      display: unset;
      line-height: 1;
    }

    &:not(:focus) {
      &::-webkit-datetime-edit-day-field,
      &::-webkit-datetime-edit-month-field,
      &::-webkit-datetime-edit-year-field,
      &::-webkit-datetime-edit-hour-field,
      &::-webkit-datetime-edit-minute-field {
        opacity: var(--field-opacity, 1);
      }
    }

    &.no-value {
      --field-opacity: 0;
    }
  }
}

.form:has(.confirm-dialog) {
  .inner-form {
    display: none;
  }
}
