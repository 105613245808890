.icon {
  --size: 1.5rem;
  width: var(--size);
  height: var(--size);
  flex: 0 0 var(--size);
  pointer-events: none;
  user-select: none;
}

.icon--arrow {
  --size: 3.125rem;
}

.icon--menu {
  --size: 1.75rem;
}

.icon--arrow-down {
  --size: 2.25rem;
}

.icon--add {
  --size: 2.5rem;
}
