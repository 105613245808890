.objects-wrapper {
  border-radius: 1rem;
  background: var(--petrol-lighter);
  padding: 1rem;
  margin-top: 2.5rem;

  @media (--media-md) {
    border-radius: 1.5rem;
    padding: 2rem;
    margin-top: 5rem;
  }

  .object {
    background-color: var(--white);
  }

  > h3 {
    margin-top: 0px !important;
  }

  .objects {
    margin-top: 1rem;
    @media (--media-md) {
      margin-top: 1.5rem;
    }
  }
}

.objects {
  display: grid;
  row-gap: 0.5rem;
  margin-top: 2.5rem;
  overflow: auto;
  max-width: 100%;

  @media (--media-md) {
    margin-top: 5rem;
  }

  h3 + &,
  p + & {
    margin-top: 1rem;

    @media (--media-md) {
      margin-top: 2rem;
    }
  }

  &:has(.object--form) > .object:not(.object--form) {
    opacity: 0.3;
    transition: opacity 250ms ease-in-out, border-color 250ms ease-in-out,
      background-color 250ms ease-in-out;
  }

  .results__noresponse {
    margin-top: 0px;
    @media (--media-md) {
      margin-top: 0px;
    }
  }
}

@starting-style {
  .objects:has(.object--form) > .object:not(.object--form) {
    opacity: 1;
  }
}

.objects--agenda {
  grid-template-columns: auto minmax(min-content, 7.5rem) min-content 1fr min-content;
}

.objects--updates {
  grid-template-columns: auto min-content;
}

.objects--people {
  grid-template-columns: 150px 1fr min-content;
}

.objects--publications {
  grid-template-columns: auto 1fr min-content;
}

.object {
  grid-column: 1 / -1;
  background-color: var(--primary-bg);
  padding: 1rem;
  border-radius: 0.5rem;
  display: grid;
  grid-template-columns: subgrid;
  gap: 1.5rem;
  border: 1px solid var(--primary-bg);
  transition: opacity 1000ms ease-in-out, border-color 1000ms ease-in-out,
    background-color 1000ms ease-in-out;
}

.object__cell--padding {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.object--form {
  position: relative;
  opacity: 1;
  grid-template-columns: 1fr;
  border: 1px solid var(--black);
  display: flex;
  padding: 1.5rem 1rem;
  transition: opacity 1000ms ease-in-out, border-color 1000ms ease-in-out,
    background-color 1000ms ease-in-out;

  @media (--media-md) {
    padding: 2.5rem 2rem;
  }
}

.object--person {
  border: 1px solid var(--gray-light);
  background-color: transparent;
  padding: 0px;
  overflow: hidden;

  .object__tools {
    padding-right: 1rem;
  }
}

.object--new {
  padding: 0px;
  grid-template-columns: 1fr;
}

.object.object--archive {
  background-color: var(--gray-lighter);
}

.object.object--repeated {
  background-color: var(--petrol-light);
}

.object.object--not-active {
  text-decoration: line-through;
  text-decoration-color: var(--black);
}

.object.object--original-event {
  background-color: var(--petrol-light); /* FIXME show differently, see #244 */
}

.object--new-button {
  display: flex;
  width: 100%;
  overflow: hidden;
  border-radius: 0.5rem;
  padding: 1rem;
  gap: 0.5rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: var(--primary);
  transition: color 200ms ease-in-out, background-color 200ms ease-in-out;

  &:hover {
    color: var(--black);
  }
}

.object__cell--tools {
  display: flex;
  justify-content: flex-end;
}

.object__tools {
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
  height: 100%;
  margin-left: auto;
  margin-right: 0;

  @media (--media-md) {
    gap: 1rem;
  }
}

/* If the element directly before .object--person isn't a person disallow moving up */
:not(.object--person) + .object--person .move-up,
/* If a .object--person element doesn't have a person directly after it disallow moving down */
  .object--person:not(:has(+ .object--person))
  .move-down {
  display: none;
}

.object__category,
.object__name,
.object__cell--date {
  color: var(--primary);
}

ul.object__category {
  display: flex;
  flex-wrap: wrap;

  li {
    text-wrap: balance;
    &:not(:last-child):after {
      content: ",\00a0";
    }
  }
}

.object__cell--time {
  white-space: nowrap;
}

.object__info {
  color: var(--fg);
  text-decoration: none;
}

a.object__info {
  &.active,
  &:hover {
    color: var(--fg-hover);
  }
}

.object__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;

  @media (--media-md) {
    top: 1rem;
    right: 1rem;
  }
}

.object__image {
  width: 100%;
  aspect-ratio: 48 / 35;
  object-fit: cover;
  height: 100%;
}
