.teaser {
  display: block;
  position: relative;
  border-radius: 0.5rem;
  border: 1px solid var(--gray-light);
  background: var(--white);
  background-size: cover;
  color: var(--fg);

  transition: box-shadow 200ms ease-in-out;

  &:hover,
  &:active {
    box-shadow: var(--box-shadow-2);
  }
}

.teaser--rubric {
  height: 100%;
  background-color: var(--rubric-color, transparent);
  border-color: var(--rubric-color, var(--gray-light));
  --padding: 1.5rem 1.5rem 4.875rem 1.5rem;

  @media (--media-md) {
    --padding: 2rem 2rem 6.375rem 2rem;
  }
}

.teaser--article,
.teaser--agenda {
  height: auto;
  --padding: 1.5rem;

  @media (--media-md) {
    --padding: 2rem;
  }
}

.teaser--agenda {
  border: none;
  background: var(--primary-bg);
  --padding: 1.5rem 1rem;

  @media (--media-md) {
    --padding: 2rem 1.5rem;
  }
}

.teaser--parish {
  --fg: var(--black);
  border: none;
  aspect-ratio: 1 / 1;
  background-color: var(--primary-bg);
  --padding: 1rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;

  .teaser__title {
    --flow-space: 0px;
  }

  @media (--media-md) {
    --padding: 1.5rem;
  }
}

.teaser--person {
  border: none;
  background-color: var(--primary-bg);
  --padding: 1.5rem 1rem;

  @media (--media-md) {
    --padding: 2rem 1.5rem;
  }

  .teaser__title {
    --flow-space: 1rem;
  }

  &:hover,
  &:active {
    box-shadow: none;
  }
}

.teaser__inner {
  display: block;
  height: 100%;
  padding: var(--padding, 0px);
}

.teaser--fg-white {
  --fg: var(--white);
}

.teaser__rubric-name {
  padding: 0px 4px;
  background: var(--secondary);
  display: inline-block;
  text-transform: uppercase;
}

.teaser__image-wrapper {
  --flow-space: 1.5rem;
  display: block;
}

.teaser__image {
  width: 100%;
  object-fit: contain;

  .teaser--article & {
    object-fit: cover;
  }

  .teaser--rubric & {
    max-height: 20rem;

    @media (--media-lg) {
      max-height: 27.5rem;
    }
  }

  .teaser--person &,
  .teaser--event & {
    aspect-ratio: 48 / 35;
    object-fit: cover;
  }
}

.teaser__pretitle {
  --flow-space: 1.25rem;
  color: var(--rubric-sup-color, var(--primary));
}

.teaser__title {
  --flow-space: 0.75rem;
  text-wrap: balance;
  hyphens: auto;
}

.teaser__excerpt {
  --flow-space: 0.75rem;
}
.teaser__date {
  --flow-space: 0.75rem;
  display: block;
}

.teaser__button {
  --flow-space: 2.5rem;
}

.teaser__icon {
  display: block;
  position: absolute;
  bottom: 1rem;
  right: 1.5rem;
  margin: 0;

  @media (--media-md) {
    bottom: 2rem;
    right: 2rem;
  }
}

.teaser__category {
  text-transform: uppercase;
  color: var(--primary);
}

ul.teaser__category {
  display: flex;
  flex-wrap: wrap;

  li {
    &:not(:last-child):after {
      content: ",\00a0";
    }
  }
}

.teaser__name {
  /* margin-top: 1.5rem;  */
  hyphens: auto;
}

.teaser__city {
  hyphens: auto;
}

.teaser__mission {
  color: var(--black);
  padding: 0px 4px;
  background: var(--secondary);
  display: inline-block;
  text-transform: uppercase;
}

.teaser__function {
  --flow-space: 0.5rem;
}

a.teaser__mail {
  --flow-space: 0.5rem;
  display: inline-block;
  &:hover,
  &:active {
    color: var(--fg-hover);
  }
}

.teaser__body {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: var(--primary-bg);
  padding: 1rem;

  .teaser--mission & {
    background-color: var(--petrol-light);
  }
}

.teaser__with-livestream {
  --flow-space: 0px;
  height: 1.5rem;
  position: absolute;
  bottom: 1.5rem;
  right: 1rem;
  pointer-events: none;

  @media (--media-md) {
    bottom: 2rem;
    right: 1.5rem;
  }
}

.family-color {
  .bricks:nth-child(odd) {
    /* 1 */
    .teaser:nth-child(3n + 1) {
      --rubric-color: var(--lighter);
      --rubric-sup-color: var(--primary);
      /* reset to default Colors */
      --button-fg: var(--button-default-fg);
      --button-bg: var(--button-default-bg);
      --button-border: var(--button-default-border);
      --button-hover-fg: var(--button-default-hover-fg);
      --button-hover-bg: var(--button-default-hover-bg);
      --button-hover-border: var(--button-default-hover-border);
    }

    /* 2 */
    .teaser:nth-child(3n + 2) {
      --rubric-color: var(--dark);
      --rubric-sup-color: var(--black);
      /* Copy of .button--dark */
      --button-fg: var(--button-dark-fg);
      --button-bg: var(--button-dark-bg);
      --button-border: var(--button-dark-border);
      --button-hover-fg: var(--button-dark-hover-fg);
      --button-hover-bg: var(--button-dark-hover-bg);
      --button-hover-border: var(--button-dark-hover-border);
    }

    /* 3 */
    .teaser:nth-child(3n) {
      --rubric-color: unset;
      --rubric-sup-color: var(--black);
      /* reset to default Colors */
      --button-fg: var(--button-default-fg);
      --button-bg: var(--button-default-bg);
      --button-border: var(--button-default-border);
      --button-hover-fg: var(--button-default-hover-fg);
      --button-hover-bg: var(--button-default-hover-bg);
      --button-hover-border: var(--button-default-hover-border);

      .teaser__title span {
        padding: 0px 0.2em;
        background-color: var(--light);
        box-decoration-break: clone;
        line-height: calc(120% + 0.25em);
      }
    }
  }

  .bricks:nth-child(even) {
    /* 4 */
    .teaser:nth-child(3n + 1) {
      --rubric-color: var(--light);
      --rubric-sup-color: var(--primary);
      /* reset to default Colors */
      --button-fg: var(--button-default-fg);
      --button-bg: var(--button-default-bg);
      --button-border: var(--button-default-border);
      --button-hover-fg: var(--button-default-hover-fg);
      --button-hover-bg: var(--button-default-hover-bg);
      --button-hover-border: var(--button-default-hover-border);
    }

    /* 5 */
    .teaser:nth-child(3n + 2) {
      --rubric-color: unset;
      --rubric-sup-color: var(--primary);
      /* reset to default Colors */
      --button-fg: var(--button-default-fg);
      --button-bg: var(--button-default-bg);
      --button-border: var(--button-default-border);
      --button-hover-fg: var(--button-default-hover-fg);
      --button-hover-bg: var(--button-default-hover-bg);
      --button-hover-border: var(--button-default-hover-border);

      .teaser__title {
        color: var(--darker);
      }
    }

    /* 6 */
    .teaser:nth-child(3n) {
      --rubric-color: var(--lighter);
      --rubric-sup-color: var(--primary);
    }
  }
}
