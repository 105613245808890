.footer {
  margin-top: 5rem;
  padding: 2rem 0px;
  background: var(--primary);
  color: var(--white);

  @media (--media-md) {
    margin-top: 10rem;
    padding: 4rem 0px;
  }
}

.footer__inner {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;

  @media (--media-md) {
    gap: 2rem;
  }
}

.footer__cell {
  flex: 1;
  flex-basis: 200px;
}

.footer__menu {
  display: flex;
  flex-direction: column;
}

.footer__title-link,
.footer__link,
.footer__menu-link {
  transition: color 200ms ease-in-out;

  &:hover,
  &:active {
    color: var(--black);
  }

  &.active {
    color: var(--white);
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: -1rem;
      transform: translateY(-50%);
      height: 0.5rem;
      width: 0.5rem;
      display: block;
      background: url(../icons/active-sub.svg) center center no-repeat;
    }
  }
}

.footer__logo {
  width: 171px;
  height: auto;
  margin-left: auto;
}

.footer__title-link {
  display: inline-block;
  margin-top: 1rem;

  @media (--media-md) {
    margin-top: 2rem;
  }
}
