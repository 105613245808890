.buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1.25rem;
  flex-wrap: wrap;

  @media (--media-md) {
    margin-top: 2.5rem;
  }
}

.buttons--less-margin {
  margin-top: 0.75rem;

  @media (--media-md) {
    margin-top: 1.5rem;
  }
}

.buttons--left {
  justify-content: flex-start;
  align-items: flex-start;
}

.buttons--vertical {
  flex-direction: column;
  margin-top: 0px;

  @media (--media-md) {
    margin-top: 0px;
  }
}

.buttons--right {
  justify-content: flex-end;
  align-items: flex-end;
}

.buttons--absolute {
  position: absolute;
  right: 1rem;
  top: 0px;
  justify-content: flex-start;
  align-items: flex-end;
}

button {
  padding: 0;
  overflow: visible;
  border: 0;
  font: inherit;
  -webkit-font-smoothing: inherit;
  letter-spacing: inherit;
  color: inherit;
  background: none;
  cursor: pointer;
  appearance: none;
}

/*  Button S */
dialog.prose-editor-dialog button,
input[type="file"]::file-selector-button,
.button {
  --flow-space: 1.5rem;
  display: inline-block;
  user-select: none;
  text-decoration: none;
  width: auto;
  text-align: center;
  padding: 7px 23px;
  border-radius: 1.5rem;
  white-space: nowrap;
  transition: color 200ms ease-in-out, background-color 200ms ease-in-out,
    border-color 200ms ease-in-out;

  color: var(--button-fg);
  background: var(--button-bg);
  border: 1px solid var(--button-border);

  &:hover,
  &:active {
    color: var(--button-hover-fg);
    background: var(--button-hover-bg);
    border-color: var(--button-hover-border);
  }
}

input[type="file"]::file-selector-button {
  cursor: pointer;
  margin-right: 1rem;
}

input[type="file"]::file-selector-button:hover {
  color: var(--button-hover-fg);
  background: var(--button-hover-bg);
  border-color: var(--button-hover-border);
}

input[type="file"][disabled] {
  cursor: not-allowed;
  filter: grayscale(1);
  opacity: 0.4;
}

.button--large {
  border-radius: 48px;
  padding: 19px 31px;

  @media (--media-sm-only) {
    padding: 15px 23px;
  }
}

.button--dark {
  --button-fg: var(--button-dark-fg);
  --button-bg: var(--button-dark-bg);
  --button-border: var(--button-dark-border);
  --button-hover-fg: var(--button-dark-hover-fg);
  --button-hover-bg: var(--button-dark-hover-bg);
  --button-hover-border: var(--button-dark-hover-border);
}

.button--toggle {
  --button-fg: var(--button-toggle-fg);
  --button-bg: var(--button-toggle-bg);
  --button-border: var(--button-toggle-border);
  --button-hover-fg: var(--button-toggle-hover-fg);
  --button-hover-bg: var(--button-toggle-hover-bg);
  --button-hover-border: var(--button-toggle-hover-border);

  &.active {
    --button-fg: var(--button-toggle-active-fg);
    --button-bg: var(--button-toggle-active-bg);
    --button-border: var(--button-toggle-active-border);
  }
}

/* .button--filter {
  color: var(--button-filter-fg);
  background: var(--button-filter-bg);
  border: 1px solid var(--button-filter-border);

  &:hover,
  &:active {
    color: var(--button-filter-hover-fg);
    background: var(--button-filter-hover-bg);
    border-color: var(--button-filter-hover-border);
  }

  &.active {
    color: var(--button-filter-active-fg);
    background: var(--button-filter-active-bg);
    border-color: var(--button-filter-active-border);
  }
} */

.button--icon {
  --size: 1.5rem;

  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
}

.button--only-icon {
  --size: 1.5rem;
  padding: 7px 19px;

  .icon {
    display: block;
  }
}

.button--dashboard {
  padding: 15px 23px;
  gap: 1rem;
  --button-fg: var(--fg);
  --button-bg: var(--secondary);
  --button-border: var(--secondary);
  border-radius: 0px;
  margin-top: 0.5rem;

  @media (--media-mobile) {
    display: block;
    width: 100%;
  }
}

.button--export {
  --button-bg: var(--green-light);
  --button-border: var(--green-light);
}

.button--only-icon-small {
  --size: 1.5rem;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.5rem;
  padding: 0.5rem;
  border: none;

  .icon {
    display: block;
  }
}

.fake-button-only-icon-small {
  --size: 1.5rem;
  width: 1.75rem;
  height: 1.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  padding: 2px;
  border: none;
  cursor: help;

  .icon {
    display: block;
  }
}

.fake-button {
  --size: 1.5rem;
  user-select: none;
  text-decoration: none;
  white-space: nowrap;
  color: var(--black);
  background: color-mix(in srgb, var(--white) 50%, transparent);
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.5rem;
  padding: 0.5rem;
  border: none;
  cursor: help;

  .icon {
    display: block;
  }
}

.button-icon-clear {
  --size: 1.5rem;
  width: 2.5rem;
  height: 2.5rem;
  transition: color 200ms ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;

  color: var(--fg);

  &:hover,
  &:active {
    color: var(--fg-hover);
  }
}

.button--delete {
  margin-left: auto;
  margin-right: 0px;
}

.button--danger {
  color: var(--white);
  background: var(--error-button);
  border-color: var(--error-button);
}
