.responsive-embed,
.f3cc-embed {
  aspect-ratio: 16 / 9;
}

.responsive-embed {
  --flow-space: 1.5rem;
  position: relative;
  overflow: hidden;
  border-radius: 0.5rem;

  @media (--media-md) {
    --flow-space: 3rem;
  }

  :is(iframe) {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}

.f3cc-embed {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.html,
.f3cc-embed[data-provider="youtube"],
.youtube {
  margin-left: auto;
  margin-right: auto;
  max-width: var(--content-width);
}

.html,
.video,
.youtube {
  --flow-space: 1.5rem;
  @media (--media-md) {
    --flow-space: 3rem;
  }
}

.video {
  width: 100%;
  border-radius: 0.5rem;
  overflow: hidden;
  display: block;
}
