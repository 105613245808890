.dashboard {
  padding-top: 1.5rem;
  @media (--media-desktop) {
    display: flex;
    gap: 1rem;
    padding-top: 3.5rem;
  }
}

.main--dashboard {
  position: relative;
  --layout-width: var(--dashboard-layout-width);
  flex: 1 1 auto;

  @media (--media-desktop) {
    .container {
      margin-left: 0;
    }
  }
}

.dashboard__aside {
  width: 100%;
  flex: 0 0 var(--aside-width);
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (--media-mobile) {
    padding: 1rem;
  }

  @media (--media-desktop) {
    width: var(--aside-width);
    gap: 225px;
  }
}

.dashboard__logo-link {
  display: inline-block;
  width: auto;

  @media (--media-desktop) {
    justify-content: flex-end;
    align-self: flex-end;
  }
}

.dashboard__logo {
  display: inline-block;
  height: 28px;
}

.dashboard-nav {
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (--media-desktop) {
    position: sticky;
    top: 3rem;
  }
}

.dashboard-nav__link {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.5rem;
  background: var(--primary-bg);
  color: var(--primary);
  padding: 1.5rem 3rem;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;

  @media (--media-mobile) {
    border-radius: 0.5rem;
  }

  &.active,
  &:hover {
    background: var(--petrol-light);
    color: var(--black);
  }
}

.dashboard-nav__issue {
  align-self: flex-start;
  background: var(--secondary);
  padding: 1rem 1.5rem;
  margin-top: 1.5rem;
  display: inline-flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;

  p {
    display: block;
    white-space: pre-wrap;
  }

  @media (--media-desktop) {
    display: inline-block;
  }
}

.dashboard__header-items {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
  justify-content: space-between;
  @media (--media-mobile) {
    flex-wrap: wrap;

    .buttons--right {
      width: 100%;
    }
  }
}

@media (--media-mobile) {
  .dashboard__header-items-left {
    width: 100%;
  }
}

.messages {
  position: sticky;
  width: 100%;
  top: 1rem;
  left: 0px;
  z-index: var(--z-overlay);
  display: grid;
  grid-template-rows: auto; /* Automatische Höhe für die Zeile */
  transition: opacity 0.5s ease, grid-template-rows 0.5s ease;
  opacity: 1;
  overflow: hidden;
}

.messages--hidden {
  opacity: 0; /* Blendet das Element aus */
  grid-template-rows: 0; /* Reduziert die Höhe der Grid-Zeile auf null */

  .message {
    margin-top: 0px;
  }
}

.message {
  padding: 1rem;
  margin-top: 1rem;
  border-radius: 0.5rem;
  transition: margin-top 0.5s ease;

  &.error {
    background: var(--error-bg);
  }

  &.warning {
    background: var(--apricot-light);
  }

  &.success {
    background: var(--green-light);
  }
}

.form--dashboard {
  width: 100%;
  margin-top: 1.75rem;

  @media (--media-md) {
    margin-top: 3.5rem;
  }
}

.form--dashboard-small {
  max-width: var(--dashboard-form-width);
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  h2 + * {
    --flow-space: 2rem;
    display: block;
  }
}

.dashboard__subtitle {
  margin-top: 2.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (--media-md) {
    margin-top: 5rem;
  }

  h2 + & {
    margin-top: 1.75rem;

    @media (--media-md) {
      margin-top: 3.5rem;
    }
  }
}

.dashboard__subtitle--border {
  width: 100%;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--black);

  @media (--media-md) {
    padding-bottom: 1rem;
  }
}

.dashboard__description {
  margin-top: 0.25rem;

  @media (--media-md) {
    margin-top: 0.5rem;
  }
}

dialog.prose-editor-dialog {
  border-radius: var(--sizes-8, 8px);
  background: var(--primary-bg);
  box-shadow: var(--box-shadow-2);
  border: none;
  padding: 2rem;
  max-width: 100%;
  width: 500px;

  form {
    display: inline-flex;
    flex-wrap: wrap;

    gap: 1.5rem 1rem;

    > p {
      flex: 1 1 100%;
    }
  }
}

.dashboard-preview {
  background: var(--petrol-lighter);
  position: absolute;
  inset: 0;
  overflow: hidden;

  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  padding: 1rem;

  .buttons {
    margin-top: 0;
  }

  iframe {
    background: var(--white);
    border: none;
    flex-grow: 1;
    box-shadow: inset 0 0 5px 0 rgba(0 0 0 / 0.3);
  }
}

.dashboard-preview__approve {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
